/**
 * Record gtac event
 * @param eventName
 * @param params
 */
export const recordEvent = (eventName: string, params: object = {}) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', eventName, JSON.parse(JSON.stringify(params)));
  } else {
    console.log('gtag not found');
  }
};

/**
 * Record type selection on main Tabs using gtag custom events
 * @param type
 */
export const recordTypeSelection = (type: string) => {
  recordEvent('select_type', {
    type: type,
  });
};

/**
 * Record search places event
 * @param searchTerm
 */
export const recordSearchPlaces = (searchTerm: string) => {
  recordEvent('search_places', {
    search_term: searchTerm,
  });
};

/**
 * Record search layers event
 * @param searchTerm
 */
export const recordSearchLayers = (searchTerm: string) => {
  recordEvent('search_layers', {
    search_term: searchTerm,
  });
};

/**
 * Record filter selection event
 * @param type
 * @param filters
 */
export const recordFilterSelection = (type: string, filters: string[]) => {
  recordEvent('select_filters', {
    type: type,
    filters: filters,
  });
};

/**
 * Record select search result event
 * @param type
 * @param filter
 * @param result
 */
export const recordSelectSearchResult = (type: string, filter: string, result: string) => {
  recordEvent('select_search_result', {
    type: type,
    filter: filter,
    result: result,
  });
};

/**
 * Record show on map event
 * @param type
 * @param slug
 */
export const recordShowOnMap = (place: string, layer: string) => {
  recordEvent('show_on_map', {
    place: place,
    layer: layer,
  });
};

/**
 * Record download event
 * @param type
 * @param location
 * @param layer
 */
export const recordDownload = (type: string, location: string, layer: string) => {
  recordEvent('download', {
    type: type,
    location: location,
    layer: layer,
  });
};

/**
 * Record info button click event
 * @param place
 * @param slug
 * @param name
 */
export const recordInfoButtonClick = (place: string, slug: string, name: string) => {
  recordEvent('info_button_click', {
    id: place,
    slug: slug,
    name: name,
  });
};

/**
 * Record layer info button click event
 * @param slug
 */
export const recordLayerInfoButtonClick = (slug: string) => {
  recordEvent('layer_info_button_click', {
    slug: slug,
  });
};
